<template>
  <v-row class="forgotPasswordContainer">
    <v-col
      md="12"
      sm="12"
      style="display: flex; justify-content: center; align-items: center"
    >
      <div class="formDiv">
        <v-form>
          <v-container>
            <v-row>
              <v-col
                style="
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <v-img
                  :src="require('@/assets/general/logoLogin.png')"
                  width="120"
                  height="110"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="textDecorationBold" style="font-size: 38px; color: #263573"
                  ><b>{{ $t("resetPass") }}</b></span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col
                ><span class="textFontDecoration" style="font-size: 18px; color: #343a40">
                  {{ $t("reEnterEmail") }}</span
                >
              </v-col>
            </v-row>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="boxContainer">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="email"
                      placeholder="Email"
                      prepend-inner-icon="mdi-email-outline"
                      required
                      :error-messages="emailErrors"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      large
                      depressed
                      color="primary"
                      elevation="5"
                      :loading="loading"
                      @click="onForgotPasswordHandler"
                      class="mr-4 textFontDecoration btnContainerFp"
                    >
                      {{ $t("send") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      large
                      outlined
                      color="primary"
                      elevation="5"
                      @click="loginRedirect"
                      class="mr-4 textFontDecoration btnContainerFp"
                    >
                      {{ $t("backToConnect") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>


/* 
  Name:ForgotPassword
  Description: ForgotPassword view is a page for password reset via email.
*/
import { email, required } from "vuelidate/lib/validators";
import firebase from "firebase";
export default {
  name: "ForgotPassword",
  created() {
    const component = this;
    this.handler = function (event) {
      component.$emit("keydown", event);
    };
    window.addEventListener("keydown", (event) => {
      if (event.key === "Enter") this.onForgotPasswordHandler();
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", (event) => {
      if (event.key === "Enter") this.onForgotPasswordHandler();
    });
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  validations: {
    email: {
      email,
      required,
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.email.email) errors.push("Το email πρέπει να ειναι έγκυρο");
      return errors;
    },
  },
  methods: {
    // validateEmail() {
    //   return String(this.email)
    //     .toLowerCase()
    //     .match(
    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     );
    // },

    loginRedirect() {
      this.$router.push("/login");
    },

    async onForgotPasswordHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$router.push("/forgotPasswordSent");
        })
        .catch((error) => {
          this.$store.dispatch("onSetSnackbar", {
            open: true,
            text: error.message,
            color: "error",
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.forgotPasswordContainer {
  /* width: 100%; */
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(230, 235, 247, 1) 100%
  );
}
.btnContainerFp {
  text-transform: capitalize;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  width: 100%;
}

.formDiv {
  width: 40%;
}
.boxContainer {
  width: 70%;
}

@media screen and (max-width: 959px) {
  .formDiv {
    width: 100%;
  }
}
</style>
